import {Fighter} from "../../model/arena"
import styles from "./FightersView.module.css"
interface ArenaFightersViewProps {
    fighters:   Fighter[]
}

export function ArenaFightersView(props: ArenaFightersViewProps) {
    const fighter1 = props.fighters.find(el => el.role === 'fighter1')
    const fighter2 = props.fighters.find(el => el.role === 'fighter2')
    return (
        <div className={styles.main}>
            <div className={styles.fighterContainer}>{fighter1?.displayName}</div>
            <div className={styles.vs}>vs</div>
            <div className={styles.fighterContainer}>{fighter2?.displayName || '?'}</div>
        </div>
    )
}
