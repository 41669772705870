import {Rounds} from "../../model/arena"
import React from "react"
import styles from "./RoundsView.module.css"
import {ArenaRoundView} from "./RoundView"

interface ArenaRoundsViewProps {
    rounds:   Rounds
}

export function ArenaRoundsView(props: ArenaRoundsViewProps) {
    const data = props.rounds
    const roundNumbers = Object.keys(data).reverse()
    return (
        <>
            {
                roundNumbers.map(roundNumber => (
                    <ArenaRoundView key={`roundView${roundNumber}`} roundNumber={roundNumber} round={data[+roundNumber]}/>
                ))
            }
            {
                roundNumbers.length === 0 && (
                    <div className={styles.waiting}>
                        Ожидание игроков...
                    </div>
                )
            }
        </>
    )
}
