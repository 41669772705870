import styles from "./Location.module.css"

import React, {useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'

import {
    FaCloud,
    FaCloudMoon,
    FaCloudMoonRain,
    FaCloudRain,
    FaCloudSun,
    FaCloudSunRain, FaFan,
    FaMoon, FaShoppingBag,
    FaSun,
} from 'react-icons/fa'
import {ToTitleCase} from "../../../app/functions";
import classNames from "classnames";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {BuyEquipment, LocationEquipment, SwitchEquipment} from '../../../app/slices/locationSlice'
import {Dialog} from "../../../components/view/Dialog";
import {Button} from "../../../components/view/Button";
import {ShopDialog} from "./ShopDialog";
import {CurrencyObject} from '../../../model/interfaces'
import {Rig} from './Rig'
import {EmptyRig} from './EmptyRig'
import {FaGaugeSimple, FaGaugeSimpleHigh, FaToggleOff, FaToggleOn} from 'react-icons/fa6'
import {blowRigs, overclockRigs, toggleAllRigs, toggleRigs} from '../../../app/slices/rigsSlice'
import {Counter} from '../../../components/view/Counter'

function determineTimeOfDay(time: string) {
    // console.log('determineTimeOfDay', time);

    const hours = +time.split(":")[0];

    let result
    if (hours >= 0 && hours < 7) {
        result = 'ночь'
    } else if (hours >= 7 && hours < 12) {
        result = 'утро'
    } else if (hours >= 18) {
        result = 'вечер'
    } else {
        result = 'день'
    }

    return result
}

function getIcon(time: string, weather: string){
    // console.log('getIcon', time, weather);
    // weather = 'cloudy'
    // time = '18:30'
    const size = 35
    if (weather === 'cloudy') {
        if (['утро', 'день'].includes(time)) {
            return <FaCloudSun  size={size}/>
        } else if (['ночь'].includes(time)) {
            return <FaCloudMoon  size={size}/>
        }

        //  evening
        return <FaCloud  size={size}/>
    }

    if (weather === 'rain') {
        if (['утро', 'день'].includes(time)) {
            return <FaCloudSunRain  size={size}/>
        } else if (['ночь'].includes(time)) {
            return <FaCloudMoonRain  size={size}/>
        }

        //  evening
        return <FaCloudRain  size={size}/>
    }

    if (['утро', 'день'].includes(time)) {
        return <FaSun  size={size}/>
    } else if (['ночь'].includes(time)) {
        return <FaMoon  size={size}/>
    }

    //  evening
    return <FaSun  size={size} style={{opacity: 0.3}}/>
}

export default function Location() {
    const dispatch = useAppDispatch()
    const activeRigs = useAppSelector((state) => state.rigs.activeRigs)
    const totalRigs = useAppSelector((state) => state.rigs.totalRigs)
    const availableRigs = useAppSelector((state) => state.settings.rigsLimit)
    const intensity = useAppSelector((state) => state.stats.intensity)

    function getLabel(id: string, active = false) {
        const index = location.equipment.findIndex(el => el.id === id)
        if (index === -1) return 'Неизвестно'

        if (active) {
            return location.equipment[index].description.active ? location.equipment[index].description.active : 'Вкл'
        } else {
            return location.equipment[index].description.disabled ? location.equipment[index].description.disabled : 'Выкл'
        }
    }

    const [shopDialogueActive, setShopDialogueActive] = useState<boolean>(false)

    const location = useAppSelector(state => state.location)

    const timeOfDay = determineTimeOfDay(location.time)

    // console.log('location', location.equipment.filter(el => el.purchased))
    const icon = getIcon(timeOfDay, location.weather)

    const [equipment, setEquipment] = React.useState<LocationEquipment[]>(JSON.parse(JSON.stringify(location.equipment)));
    const handleWindowChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const newActiveStatus = event.target.checked
        dispatch(SwitchEquipment(id, newActiveStatus))

        let index = equipment.findIndex(el => el.id === id)
        if (index === -1) {
            index = equipment.length    //  push
            // equipment.find(stateEl => stateEl.id === el.id)?.active
        }

        let newState = [...equipment]
        newState[index].active = event.target.checked
        setEquipment([
            ...newState
        ])
    };

    return (
        <div>
            {/*rigs section*/}
            <div className={styles.rigs}>
                <div className={styles.rigs__list}>
                    {
                        activeRigs > 0 &&
                        <div>
                            <Rig key={`rigs_active`} enabled={true} name={`x ${activeRigs}`}/>
                        </div>
                    }
                    {
                        totalRigs > activeRigs &&
                        <div>
                            <Rig key={`rigs_nonactive`} enabled={false} name={`x ${totalRigs - activeRigs}`}/>
                        </div>
                    }
                    {
                        availableRigs > totalRigs &&
                        <div>
                            <EmptyRig name={`x ${availableRigs - totalRigs}`}/>
                        </div>
                    }

                </div>

                <div className={styles.rigsSettings__container}>
                    {/*/!*<div className={styles.rigSettings__hint}></div>*!/  what is it?*/}
                    <div className={styles.rigSettings__overclock}>
                        <div className={styles.rigSettings__overclock__description}>
                            Разгон
                        </div>
                        <div className={styles.rigSettings__overclock__optionsContainer}>
                            <div className={styles.rigSettings__overclock__option}>
                                <Button text={'75%'} selected={intensity === 75}
                                        icon={<FaGaugeSimpleHigh style={{transform: 'rotateY(180deg)'}}/>}
                                        onClick={() => dispatch(overclockRigs(75))}/>
                            </div>
                            <div className={styles.rigSettings__overclock__option}>
                                <Button text={'100%'} selected={intensity === 100}
                                        icon={<FaGaugeSimple/>}
                                        onClick={() => dispatch(overclockRigs(100))}/>
                            </div>
                            <div className={styles.rigSettings__overclock__option}>
                                <Button text={'125%'} selected={intensity === 125}
                                        icon={<FaGaugeSimpleHigh/>}
                                        onClick={() => dispatch(overclockRigs(125))}/>
                            </div>
                        </div>
                    </div>

                    <div className={styles.rigSettings__hint}></div>
                    <div className={styles.rigSettings__buttons}>
                        <Button text={'Включить все'}
                                icon={<FaToggleOn/>}
                                onClick={() => dispatch(toggleAllRigs(true))}/>
                        <Button text={'Выключить все'}
                                icon={<FaToggleOff/>}
                                onClick={() => dispatch(toggleAllRigs(false))}/>

                        <Button text={'Продуть'}
                                icon={<FaFan/>}
                                onClick={() => dispatch(blowRigs())}/>

                        <div>
                            <Counter value={activeRigs}
                                     maxValue={totalRigs}
                                     onPlus={() => dispatch(toggleRigs(1))}
                                     onMinus={() => dispatch(toggleRigs(-1))}/>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>
            {/* location section*/}
            <div>
                <div className={styles.container}>
                    <div className={styles.containerData}>
                        <div className={styles.title}>Локация</div>
                        <div className={styles.text}>{location.location}</div>
                    </div>
                </div>

                <div className={styles.container}>
                    <div className={styles.containerData}>
                        <div className={styles.title}>Время суток</div>
                        <div className={styles.text}>{ToTitleCase(timeOfDay)}</div>
                    </div>
                </div>

                <div className={styles.container}>
                    <div className={styles.containerData}>
                        <div className={styles.title}>Температура на улице</div>
                        <div className={styles.text}>{location.temp_out}°C</div>
                    </div>
                    <div className={classNames(styles.containerActions, 'pinned-right')}>
                        {icon}
                    </div>
                </div>

                <div className={styles.container}>
                    <div className={styles.containerData}>
                        <div className={styles.title}>Температура в помещении</div>
                        <div className={styles.text}>{location.temp}°C</div>
                    </div>
                </div>

                <FormGroup>
                    {
                        location.equipment.filter(el => el.purchased).map(el => {
                            return <div key={el.name} className={styles.container}>
                                <div className={styles.containerData}>
                                    {/*<div className={styles.title}>Окно</div>*/}
                                    <div className={styles.text}>{el.name}</div>
                                    <div className={styles.description}>{el.description.info}</div>
                                    {
                                        Object.keys(el.consumption).map((currency: string) => {
                                            const value = el.consumption[currency as keyof CurrencyObject]

                                            if (value && value > 0) {
                                                return <div key={'consumption_' + currency}
                                                            className={styles.description}>Потребление: {value} {currency} в
                                                    час</div>
                                            }
                                        })
                                    }
                                </div>
                                <div className={classNames(styles.containerActions, 'pinned-right')}>
                                    {/*<Switch defaultChecked />*/}
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={equipment.find(stateEl => stateEl.id === el.id)?.active}
                                                onChange={(event) => handleWindowChange(event, el.id)}
                                            />
                                        }
                                        label={getLabel(el.id, equipment.find(stateEl => stateEl.id === el.id)?.active)}
                                        labelPlacement="start"
                                    />
                                </div>
                            </div>
                        })
                    }
                </FormGroup>
                {
                    location.equipment.filter(el => !el.purchased).length > 0 &&
                    <div className={styles.buttonsContainer}>
                        <Button text={"Улучшения"}
                                icon={<FaShoppingBag/>}
                                onClick={() => setShopDialogueActive(true)}/>

                    </div>
                }


                {   /*   open dialog after 'dealer' button click    */
                    shopDialogueActive && (
                        <Dialog open={true} onClose={() => {
                            setShopDialogueActive(false)
                        }}>
                            <ShopDialog items={location.equipment.filter(el => !el.purchased)}
                                        onBuy={(item: string) => {
                                            dispatch(BuyEquipment(item))
                                            setShopDialogueActive(false)
                                        }}/>
                        </Dialog>
                    )
                }
            </div>
        </div>
    )
}
