import {Round} from "../../model/arena"
import styles from "./RoundView.module.css"
interface ArenaRoundViewProps {
    roundNumber:    string
    round:          Round
}

export function ArenaRoundView(props: ArenaRoundViewProps) {
    const fightData1 = props.round.fighter1
    const fightData2 = props.round.fighter2

    return (
        <div className={styles.main}>
            <div className={styles.header}>Раунд {props.roundNumber}</div>
            <div>
                <b>{fightData1.displayName}</b> наносит {fightData1.caption_hit.toLowerCase()}
                {
                    fightData2.caption_hit && <>
                        , <b>{fightData2.displayName}</b> пытается {fightData2.caption_move.toLowerCase()}
                    </>
                }
                {
                    +fightData1.damage !== 0 && <>
                        , но получает <b>{fightData1.damage}</b> урона
                    </>
                }
                {
                    +fightData1.damage === 0 && <>
                        &nbsp; и ему это удается!
                    </>
                }
            </div>
            <div className={styles.text}>
                <b>{fightData2.displayName}</b> наносит {fightData2.caption_hit.toLowerCase()}
                {
                    fightData1.caption_hit && <>
                        , <b>{fightData1.displayName}</b> пытается {fightData1.caption_move.toLowerCase()}
                    </>
                }
                {
                    +fightData2.damage !== 0 && <>
                        , но получает <b>{fightData2.damage}</b> урона
                    </>
                }
                {
                    +fightData2.damage === 0 && <>
                        &nbsp; и ему это удается!
                    </>
                }
            </div>
        </div>
    )
}
