import {PlayerMethod} from "../../model/arena"
import styles from "./MethodsView.module.css"
import React from "react";
interface ArenaMethodsViewProps {
    methods:        PlayerMethod[]
    onClick:        (method: string) => void
}

function SortByPriority(a: PlayerMethod, b: PlayerMethod) {
    if (a.priority < b.priority) return -1
    if (b.priority > a.priority) return 1

    return 0
}

function SortByDirection(a: PlayerMethod, b: PlayerMethod) {
    if (a.direction === 'left') return -1
    if (b.direction === 'left') return 1

    if (a.direction !== 'right') return -1
    if (b.direction !== 'right') return 1

    return 0
}

export function ArenaMethodsView(props: ArenaMethodsViewProps) {
    const data = props.methods

    const blows = data.filter(el => el.group === 'blows')
    const leftBlows = blows.filter(el => el.direction === 'left').sort((a, b) => SortByPriority(a, b))
    const rightBlows = blows.filter(el => el.direction === 'right').sort((a, b) => SortByPriority(a, b))

    const moves = data.filter(el => el.group === 'move').sort((a, b) => SortByDirection(a, b))
    // const leftMoves = data.filter(el => el.direction === 'left')
    // const rightMoves = data.filter(el => el.direction === 'right')

    const other = data.filter(el => el.group !== 'blows' && el.group !== 'move')

    console.log('methods ', data)
    console.log('blows   ', blows)
    console.log('left blows   ', leftBlows)
    console.log('right blows  ', rightBlows)
    return (
        <div className={styles.main}>
            {

                data.length > 0 && (
                    <div>
                        <div className={styles.blows}>
                            <div className={styles.left}>
                                {
                                    leftBlows.map(
                                        (method, i) => (
                                            <div>
                                                <button className={styles.button} key={'blows_left_' + i} onClick={() => props.onClick(method.method)}>{method.caption}</button>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={styles.right}>
                                {
                                    rightBlows.map(
                                        (method, i) => (
                                            <div>
                                                <button className={styles.button} key={'blows_right_' + i} onClick={() => props.onClick(method.method)}>{method.caption}</button>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className={styles.moves}>
                            {
                                moves.map(
                                    (method, i) => (
                                        <div>
                                            <button className={styles.button} key={'moves_' + i} onClick={() => props.onClick(method.method)}>{method.caption}</button>
                                        </div>
                                    ))
                            }
                        </div>
                        <div className={styles.other}>
                            {
                                other.map(
                                    (method, i) => (
                                        <div>
                                            <button className={styles.button} key={'other_' + i} onClick={() => props.onClick(method.method)}>{method.caption}</button>
                                        </div>
                                    ))
                            }
                        </div>

                    </div>

                )
            }
        </div>
    )
}
