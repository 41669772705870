import {Player, PlayerMethod} from "../../model/arena"
import styles from "./RoundView.module.css"
import React from "react";
interface AArenaSpectatorsViewProps {
    spectators: Player[]
}

export function ArenaSpectatorsView(props: AArenaSpectatorsViewProps) {
    const data = props.spectators

    return (
        <div className={styles.main}>
            {
                data.length > 0 && (
                    <h2>Зрители</h2>
                )
            }

            {
                data.map(
                    (player, i) => (
                        // <div key={player.id}>{i + 1} {player.displayName}</div>
                        <div key={player.id}>{player.displayName}</div>
                    )
                )
            }
        </div>
    )
}
