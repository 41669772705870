import React, {useEffect, useState} from "react"
import styles from "./ArenaDialog.module.css"
import {io, Socket} from 'socket.io-client'
import * as process from 'process'
import {getId, getToken} from "../../../app/store"
import {ApiPlayers, Fighter, Player, PlayerMethod, Rounds} from "../model/arena"
import {ArenaFightersView} from "./arena-components/FightersView"
import {ArenaRoundsView} from "./arena-components/RoundsView"
import {IsTMA} from "../../../app/functions";
import {ArenaMethodsView} from "./arena-components/MethodsView";
import {ArenaSpectatorsView} from "./arena-components/SpectatorsView";

function SocketLog(...args: any) {
    let msg = 'WS:'

    let needDelimiter = false
    for (const arg of args) {
        if (!needDelimiter) {
            needDelimiter = true
        } else {
            msg += ' |'
        }

        msg += ' ' + arg
    }

    console.log(msg)
}

function GetDisplayName(user: Player) {
    const invisibleSymbol = 'ᅠ ᅠ '

    if (!user.first_name.includes(invisibleSymbol) && user.first_name.trim()) return user.first_name.trim()
    if (user.username.trim()) return `@${user.username}`

    return 'unknown'
}

export function ArenaDialog() {
    const url = process.env.REACT_APP_ARENA_SOCKET_URI!
    let first = true
    const [ws] = useState<Socket>(io(url))
    const [fighters, setFighters] = useState<Fighter[]>([])
    const [rounds, setRounds] = useState<Rounds>({})
    const [spectators, setSpectators] = useState<Player[]>([])
    const [dataFetched, setDataFetched] = useState<boolean>(false)
    const [userMethods, setUserMethods] = useState<PlayerMethod[]>([])

    useEffect(() => {
        if (first) {
            first = false

            ws.on('connect', () => {
                SocketLog('connect')
            })

            ws.on('disconnect', () => {
                SocketLog('disconnect')
            })

            ws.on('error', () => {
                SocketLog('error')
            })

            ws.onAny((...args) => {
                if (args.length === 0) {
                    SocketLog('error', 'null data')
                    return
                }

                const event = args[0]
                if (event === 'hello') {
                    SocketLog('handshake')

                    ws.emit('start', {
                        id:     getId(),
                        status: getToken(),
                    })
                } else if (event === 'players') {
                    if (args.length !== 2) {
                        SocketLog('error', 'wrong arguments count')
                        return
                    }

                    const data: ApiPlayers = args[1]
                    // console.log('data', data)

                    let fighters: Fighter[] = []
                    try {
                        for (const key of Object.keys(data.arena)) {
                            const player: Player = data.arena[key as 'fighter1' | 'fighter2']!

                            let fighter = {
                                ...player,
                                displayName:    GetDisplayName(player),
                                role:           key   // fighter1 | fighter2
                            }
                            fighters.push(fighter)
                        }
                    }
                    catch (e) {
                        SocketLog('error', 'error while parsing fighters')
                    }

                    for (const player of [...data.spectators, ...fighters]) {
                        if (player.id.toString() === getId()) {
                            if (player.alert) {
                                return IsTMA() ? Telegram.WebApp.showAlert(player.alert) : alert(player.alert)
                            }
                            setUserMethods(player.methods)
                            break
                        }
                    }

                    let rounds: Rounds = data.rounds
                    delete rounds[Object.keys(rounds).length]
                    for (let key of Object.keys(rounds).reverse()) {
                        for (let fighterId of [1,2]) {
                            const fighterKey = ('fighter' + fighterId) as 'fighter1' | 'fighter2'
                            const id = rounds[+key][fighterKey].id
                            const fighter = fighters.find(el => el.id === id)
                            rounds[+key][fighterKey].displayName = fighter?.displayName ? fighter.displayName : `Unknown fighter ${fighterId}`
                        }
                    }

                    const spectators = data.spectators.map(el => {
                        return {
                            ...el,
                            displayName: GetDisplayName(el),
                        }
                    })

                    SocketLog(`got ${fighters.length} fighter(s)`)
                    SocketLog(`got ${data.spectators.length} spectator(s)`)

                    setFighters(fighters)
                    setRounds(rounds)
                    setSpectators(spectators)
                    setDataFetched(true)
                } else {
                    SocketLog('unknown event', event)
                }
            })

            ws.connect()
        }
    }, [])

    function MethodClicked(method: string) {
        console.log('method clicked', method)

        ws.emit(method, {
            id:     getId(),
            status: getToken(),
        })
    }

    return (
        <div className={styles.arenaDialog}>
            {
                dataFetched ? (
                    <div>
                        <ArenaFightersView fighters={fighters}></ArenaFightersView>
                        <ArenaMethodsView methods={userMethods} onClick={(method) => MethodClicked(method)}></ArenaMethodsView>
                        <hr/>
                        <ArenaRoundsView rounds={rounds}></ArenaRoundsView>
                        <hr/>
                        <ArenaSpectatorsView spectators={spectators}></ArenaSpectatorsView>

                    </div>
                ) : (<h2>Загрузка...</h2>)
            }
        </div>
    )
}
